<template>
    <div v-if="label" class="mt-5 ml-5">
        <v-btn color="primary" depressed large @click="showSearch">
            {{ label }}
            <v-icon color="#005851" small class="ml-3">icon-q-search</v-icon>
        </v-btn>
    </div>
    <div v-else>
        <v-btn :color="color" small icon fab @click="showSearch">
            <v-icon color="#005851" small>icon-q-search</v-icon>
        </v-btn>
    </div>
</template>

<script>
export default {
    name: "QSearch",
    props: {
        label: { type: String },
		color: { type: String, default: '#005851' },
	},
    data: function() { // data internal to component (not available outside)
        return {
            active: false,
            timeout_close: null,
            keywords: '',
        }
    },
    mounted: function() {

    },
    computed: {

    },
    methods: {
        showSearch: function() {
            this.$store.commit('updateShowSearch', true)
        },
    },
    watch: {

    },
}

</script>
<style scoped>
</style>
