export const topSupportQuestions = [{
        question: 'How do I get contracted with a carrier? ',
        answer: 'Please communicate with your Agency Owner and/or Administrator for further details on how to get contracted with a carrier. Agency Owners and Administrators request appointments on behalf of their downlines as any request from the agent will not be fulfilled. AOs and Administrators please refer to the <a href=\"https://sfglife.typeform.com/to/SlvNknLj?typeform-source=hq.quility.com\" target=\"_blank\"> Pre-Appointment Request Form<\/a>.',
    },
    {
        question: 'How do I add a product, modify, or cancel my Quility Tech subscription?',
        answer: 'First, please login to <a href=\"https://quility.auth0.com/samlp/OgzWlZHuZ84QVlorfklBFO3iSfPrFMzp\">Quility Tech<\/a>. Once logged in: -To add a service, click on “Products” to add any products to a current subscription. -To modify or cancel your subscription, click My Account>Subscriptions. You can then remove services individually or cancel your subscription entirely.',
    },
    {
        question: 'How do I find out more information about upcoming events?',
        answer: 'Go to <a href=\"https://QuilityAgentEvents.com\" target=\"_blank\">QuilityAgentEvents.com<\/a> and there is information there! Looking for bootcamps, corporate overviews and other events? Calendars.sfglife.com is your resource for those!',
    },
    {
        question: 'How do I resign or terminate my contract as an agent?',
        answer: 'Please fill out <a href=\"https://form.jotform.com/211055435310037\" target=\"_blank\">this Resignation Form<\/a> if you want to resign.',
    },
    {
        question: 'How do I update my personal information? (email, phone, address, EFT, AML and E&O)?',
        answer: 'To update your personal information (email, phone, address, EFT, AML and E&O), <a href=\"https://form.jotform.com/230864916312052\" target=\"_blank\">please submit this form</a>.',
    },
    {
        question: 'What carrier(s) am I currently contracted with and/or what is my present status(es)?',
        answer: 'There are several ways to review and retrieve carrier information. <ol><li> Please ensure your completion of any contract links received from SuranceBay. ATTENTION: If it has NOT been 15 business days since the completion of your SuranceBay e-mailed contracting link(s), then you are still within the carriers’ processing timeframes and should not expect any status(es) updates nor writing number(s) until the 15th business day.<\/li> <li> Check your e-mail, especially spam or junk folders, for any communication from the carrier(s). Once an agent number has been assigned, the carrier will typically e-mail you. ONLY if it has been outside the carriers’ 14 business days processing timeframes, might you also call the carrier(s) to inquire on your contracting status(es)<br>NOTE: When calling, please ask for the Contracting/Licensing Department. The Sales Desk will not have the most up to date nor accurate information. <br>Please go to <a href=\"/page/available-carriers\">Available Carriers<\/a> for further information<br><\/li> <li> Refer to the <a href=\"/user/profile\"> ‘Licensing’ tab on your Quility HeadQuarters (HQ) profile<\/a> for a list of appointed carrier writing numbers along with an array of submitted carrier contracts and/or revisions.<br><\/li> <li>Consult with your Agency Owner (AO) and/or Administrator(s) as they have access to all the carrier websites and the ability to discern who all is in their carrier hierarchies once the appointments have been processed.<\/li><\/0l>',
    },
]
