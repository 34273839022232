<template>
    <v-card>
        <!--<v-card-title>-->
        <QDataTableHeader fluid class="q_leads_1" :id="entity + '_datatable'">
            <template #left>
                <p class="q-display-3 white--text">{{ title }}</p>
            </template>
        </QDataTableHeader>
        <!--<v-row class="d-flex justify-start" v-if="toggleFilters">
            <q-filters dark ref="filters" :hide-button="true" v-model="filters" :available-filters="available_filters" :keys="filter_keys"></q-filters>
        </v-row>//-->
        <QExpandableDataTable auto xs="2" :footer-props="{itemsPerPageOptions:[10,25,50,100]}"  :mobile-breakpoint="2" :items-per-page.sync="rows" :search="search" :headers="the_headers" :items="all_data" item-key="$id" class="elevation-0 clickable pointer" :loading="loading" @click:row="viewTicket">
            <!--adds status icons-->
            <template v-slot:item.status="{ item }">
                <span class="nowrap" v-if="item.status == 'new'">
                    <div>
                        <v-icon color="#0083EB" medium class="pr-3">fas fa-file-alt</v-icon>
                        {{ item.status }}
                    </div>
                </span>
                <span class="nowrap" v-if="item.status == 'open'">
                    <div>
                        <v-icon color="#6ECF93" medium class="pr-3">fas fa-file-alt</v-icon>
                        {{ item.status }}
                    </div>
                </span>
                <span class="nowrap" v-if="item.status == 'pending'">
                    <div>
                        <v-icon color="#FF9F1C" medium class="pr-3">fas fa-file-alt</v-icon>
                        {{ item.status }}
                    </div>
                </span>
                <span class="nowrap" v-if="item.status == 'on-hold'">
                    <div>
                        <v-icon color="amber accent-4" medium class="pr-3">fas fa-exclamation-triangle</v-icon>
                        {{ item.status }}
                    </div>
                </span>
                <span class="nowrap" v-if="item.status == 'solved'">
                    <div>
                        <v-icon color="#6ECF93" medium class="pr-3">fas fa-check</v-icon>
                        {{ item.status }}
                    </div>
                </span>
                <span class="nowrap" v-if="item.status == 'closed'">
                    <div>
                        <v-icon color="#99CDF7" medium class="pr-3">fas fa-check-circle</v-icon>
                        {{ item.status }}
                    </div>
                </span>
            </template>
            <!--adds view ticket icon -->
            <template v-slot:item.id="{ item }">
                <span class="nowrap">
                    <v-btn text small class="q_new_b_1--text  body-2" @click="viewTicket(item)">
                        <v-icon small>fas fa-eye</v-icon>
                    </v-btn>
                </span>
            </template>
			<template v-slot:item.subject="{ item }">
				#{{ item.id }} - {{ item.subject }}
			</template>
        </QExpandableDataTable>
    </v-card>
</template>
<script>
import QDataTableMixin from "./QDataTableMixin"
import SupportTicket from '@/store/Models/SupportTicket'
import QuilityAPI from '@/store/API/QuilityAPI.js'
import { debounce } from 'vue-debounce'
import QDataTableHeader from "./base/QDataTableHeader.vue"
import QExpandableDataTable from "./QExpandableDataTable.vue"

export default {
    //override this.
    name: "QSupportTicketsDataTable",
    mixins: [QDataTableMixin],
    data: function() {
        return {
            entity: 'support_ticket',
            sync_dialog: false,
            //use this to "preset" a filter
            //filters: { 'Status': 'Active' },
            firstPull: true // this keeps the auto scrolling on data reloads from happening on the first load.
        }
    },
    mounted: function() {
        //this.refreshData();
    },
    computed: {
        filter_keys: function() {
            return SupportTicket.state().filter_keys;
        },
        all_data: function() {
            return SupportTicket.query().orderBy('updated_at', 'desc').get()
        },
        the_headers: function() {
            return [
				{
                    text: 'Status',
                    value: 'status',
                    class: "nowrap",
                    sortable: false,
                }, 
				{
                    text: 'Subject',
                    value: 'subject',
                    sortable: false,
                }, 
				{
                    text: 'Created',
                    value: 'created_at',
                    sortable: false,
                }, 
				{
                    text: 'Last Update',
                    value: 'updated_at',
                    sortable: false,
                },
                {
                    text: '',
                    value: 'id',
                    class: "nowrap",
                    sortable: false,
                }
            ]
        },
    },
    methods: {
        loadData: debounce(function(opt) {
            QuilityAPI.getTickets();
            this.firstPull = false;
        }, 200),
        viewTicket: function(item) {
            this.$router.push({ name: 'ViewTicket', params: { 'id': item.id } });
        },
    },
    watch: {

    },
    components: {
        QDataTableHeader, QExpandableDataTable,
    }
}

</script>
<style scoped>
.nowrap {
    white-space: nowrap;
}

</style>
