<template>
    <v-container fluid class="common-solutions">
        <v-row justify="center" align="start">
            <v-progress-linear v-if="loading" indeterminate color="primary"></v-progress-linear>
            <v-row v-else class="full-width d-flex justify-center" :class="{ 'px-4': $vuetify.breakpoint.smAndUp }">
                <v-col cols="12" sm="7">
                    <v-card height="100%">
                        <v-card-title style="background-color:#360568; color:white;">
                            <strong>Common {{ category.category }} Questions</strong>
                        </v-card-title>
						<div class="common-solutions__content">
							<slot name="prepend" />
							<div class="common-solutions__main-content">
								<div class="pa-4" v-if="loading">
									<v-progress-circular indeterminate color="primary" />
								</div>
								<v-expansion-panels class="common-solutions__main-content" v-else>
                                    <template v-for="(page, i) in pages">
                                        <v-expansion-panel v-if="hasPermissions(page)" :key="i">
                                            <v-expansion-panel-header class="q-headline">
                                                <strong>{{page.title}}</strong>
                                            </v-expansion-panel-header>
                                            <v-expansion-panel-content>
                                                <page-view :page="page" />
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>
                                    </template>
								</v-expansion-panels>
							</div>
						</div>
                    </v-card>
                </v-col>
                <v-col cols="12" md="5">
                    <v-card height="100%">
                        <v-card-title style="background-color:#360568; color:white;">
                            <strong>{{ category.category }} Resources</strong>
                        </v-card-title>
                        <div dense class="resource-list">
							<div class="pa-4" v-if="loading">
								<v-progress-circular indeterminate color="primary" />
							</div>
							<v-list v-else style="width: 100%">
								<v-list-item v-for="(item,i) in resources" :key="i" @click="resourceLink(item)" target="_tab" two-line>
									<v-icon small v-if="item.external_url != 'internal'" left>fas fa-external-link-alt</v-icon>
									<v-icon small v-else left>fas fa-link</v-icon>
									<v-list-item-content>
										<v-list-item-title>
											<strong>{{ item.title }}</strong>
										</v-list-item-title>
										<v-list-item-subtitle v-if="item.description">{{ item.description }}</v-list-item-subtitle>
									</v-list-item-content>
								</v-list-item>
							</v-list>
                        </div>
                    </v-card>
                </v-col>
            </v-row>
            <v-row class="pa-5 ma-0 justify-center">
                <SubmitTicketCard :category="category.category" v-if="!loading" class="mb-8" />
            </v-row>
        </v-row>
    </v-container>
</template>
<script>
import SupportPage from '@/store/Models/SupportPage'
import PageView from '@/CMS/views/index-no-template.vue'
import SubmitTicketCard from '../../components/support/SubmitTicketCard.vue'

export default {
    props: ['category'],
    data() {
        return {
            search: '',
        }
    },
    computed: {
        'pages': function() {
            const pages = SupportPage.query()
                .where('category_id', this.category.id)
                .where('external_url', (url) => (url == '' || url == null))
                .orderBy('order')
                .get()

            return pages.filter(page => {
                return page.division == 'All' || this.hasDivision([page.division])
            })
        },
        'resources': function() {
            let resources = SupportPage.query()
                .where('category_id', this.category.id)
                .where('external_url', (url) => (url != '' && url != null))
                .orderBy('order')
                .get()
            if (this.hasRole(['Exec', 'SuperAdmin', 'Staff']))
                return resources.filter(page => page.status != 'trash')
            return resources.filter(page => page.status == 'publish' || page.status == null)
        },
        loading: function() {
            return this.$store.state.entities.support_page.loading
        }
    },
    methods: {
        getTarget: function(link) {
            if (link.substring(0, 1) == "/") {
                return ""
            }
            return "_tab";
        },
        resourceLink: function(item) {
            if (item.external_url && item.external_url != "internal") {
                window.open(item.external_url, "_blank");
            } else {
                this.$router.push("/page/" + item.slug)
            }
        },
        hasPermissions(page) {
            return (page.status !== 'draft' && page.status !== 'trash') || this.hasPermission(['manage:Content'])
        },
    },
    components: {
        PageView,
        SubmitTicketCard
    }
}
</script>
<style lang="scss">
.q-multi-line-btn {
    .v-btn__content {
        width: 100%;
    }
}

.common-solutions {
	&__content {
		display: flex;
		justify-content: center;
	}

	&__main-content {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.resource-list {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
}
</style>