<template>
    <v-card v-bind="$attrs">
        <slot />
    </v-card>
</template>

<script>
export default {
    name: 'SupportCard',
}
</script>

<style lang="scss" scoped>
.v-card {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #a9a9a9;
    box-shadow: none;
    padding: 18px;
}
</style>