<template>
    <div :class="$vuetify.breakpoint.xs ? 'mx-2' : 'mx-8'">
      <h2>
          Funnel Dashboard
          <v-btn icon color="q_blue_1" class="ml-1" @click="reloadData">
            <v-icon small>fas fa-sync-alt</v-icon>
          </v-btn>
      </h2>
      <v-progress-linear v-if="!funnelLoaded" indeterminate />
      <funnel-summary :items="funnelData" v-else></funnel-summary>
      <div center class="mt-6">
        <v-btn @click="viewWaitlist">
            View Waitlist
        </v-btn>
      </div>
    </div>
</template>

<script>
import { FunnelStats, getFunnelStats } from '../api';
import FunnelSummary from '../components/FunnelSummary.vue'

export default {
    components: {
		// QBenefitsSubmenu,
		FunnelSummary,
	},
	props: {
		showSubmenu: { type: Boolean, default: false },
        agent: { type: Object},
	},
    created () {
		this.reloadData()
	},
    data: function(){
        return {
            funnelLoaded : false,
            funnelData : { type : FunnelStats, value : {
                "WaitlistCount": 0,
                "CertifiedCount": 0,
                "FunnelAccessCount": 0,
                "SubscriptionCount": 0,
                "MonthlySubscriptionTotal": 0,
            } }
        }
    },
    computed : {
        agentCode() {
            //return this.agent ? this.agent.AgentCode : this.user.Agent.AgentCode
            return this.user.Agent.AgentCode
        }
    },
    methods: {
        reloadData () {
			this.loadFunnelData()
		},
        loadFunnelData(){
            this.funnelLoaded = false;
            this.funnelData = {
                "WaitlistCount": 0,
                "CertifiedCount": 0,
                "FunnelAccessCount": 0,
                "SubscriptionCount": 0,
                "MonthlySubscriptionTotal": 0,
            }
            getFunnelStats(this.agentCode).then((res) => {
                if (res.error) {
                    this.showError('There was a problem getting the data. ', res.message)
                    this.funnelLoaded = true
                } else {
                    this.funnelData = res
                    this.funnelLoaded = true
                }
            }).catch((err) => {
                console.log(err);
                this.funnelLoaded = true
            })
			
        },
        viewWaitlist(){
            this.$router.push('/form/funnel-waitlist/data')
        }
    }
}
</script>

