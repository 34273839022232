<template>
    <SupportCard class="text-center">
        <v-icon class="mb-4 lightbulb-icon">icon-q-lightbulb</v-icon>
        <h5>Request a Feature</h5>
        <p>Submit a support ticket and let us know how we can make HQ better</p>
        <v-hover v-slot="{ hover }">
            <v-btn class="my-4" :color="hover ? '#fff' : '#360568'" dark depressed to="form/suggest-an-hq-feature" :style="{
 'color': hover ? '#360568' : 'white' }">
                SUBMIT YOUR IDEA
            </v-btn>
        </v-hover>
    </SupportCard>
</template>
<script>
import SupportCard from './base/Card.vue'

export default {
    name: 'RequestFeatureCard',

    components: {
        SupportCard
    }
}
</script>
<style scoped>
.lightbulb-icon.v-icon.v-icon {
    font-size: 2.5rem !important;
    color: #000;
}
</style>