<template>
    <SupportCard class="top-support-questions">
        <h4 class="text-center mb-4">Top Support Questions</h4>

        <v-expansion-panels accordion class="elevation-0">
            <v-expansion-panel
                v-for="(item,i) in items"
                :key="i"
            >
                <v-expansion-panel-header>{{ item.question }}</v-expansion-panel-header>
                <v-expansion-panel-content>
                <div v-html="item.answer"></div>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </SupportCard>
</template>

<script>
import SupportCard from './base/Card.vue'
import { topSupportQuestions } from './data/top-support-questions'

export default {
    name: 'TopSupportQuestions',

    components: {
        SupportCard
    },

    props: {
        items: { type: Array, default: () => topSupportQuestions }
    },
}
</script>

<style lang="scss">
.top-support-questions {
    h4 {
        font: normal normal bold 22px/25px Montserrat;
    }
    
    .v-expansion-panel {
        border: 1px solid #CECACA;

        &:not(:first-child) {
            border-top: none;
        }

        &::before {
            box-shadow: none !important;
        }
    }
    .v-expansion-panel-header {
        font: normal normal normal 20px/25px Montserrat;
    }
    .v-expansion-panel-content {
        font: normal normal 300 18px/25px Montserrat;
    }
}
</style>