<template>
  <v-container class="support">
    <v-row>
      <div class="full-width">
        <h1 class="text-center mb-0">Search Our Knowledge Base</h1>
        <h2 class="text-center mt-3">for answers, documents, links and more</h2>
      </div>
      <div class="full-width mt-3">
        <SearchForm class="my-8 px-12" />
      </div>
    </v-row>
    <v-row class="mt-10">
      <v-col cols="12">
        <h2>FAQ’s & Resources</h2>
      </v-col>
      <v-col cols="12" md="7" lg="8">
        <ResourcesGrid :items="categories" />
      </v-col>
      <v-col cols="12" md="5" lg="4">
        <TopSupportQuestions class="mb-8" />
        <SubmitTicketCard class="mb-8" v-if="notRole(['SalesRep', 'UnlicensedAgent'])" />
        <RequestFeatureCard v-if="notRole(['SalesRep', 'UnlicensedAgent'])" />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Category from "@/store/Models/Category";
import ResourcesGrid from "../../components/support/resources/Grid.vue";
import SearchForm from "../../components/support/SearchForm.vue";
import TopSupportQuestions from "../../components/support/TopSupportQuestions.vue";
import SubmitTicketCard from "../../components/support/SubmitTicketCard.vue";
import RequestFeatureCard from "../../components/support/RequestFeatureCard.vue";

const iconsMap = {
  "Agency Building": "icon-q-agency-building",
  "Bonuses & Payments": "icon-q-benefits",
  "Contests & Leaderboards": "icon-q-stats-select",
  Contracting: "icon-q-carriers-products",
  "Quility Events": "icon-events",
  Leads: "icon-q-shopping",
  Marketing: "icon-q-marketing",
  "New Business": "icon-q-new-business",
  MyQuility: "icon-myquility",
  "Quility Tech": "icon-q-qtech",
  Summit: "icon-q-summit-icon",
  Other: "icon-q-elipses-h",
  Switchboard: "icon-switchboard",
  "Advanced Markets": "icon-advanced-markets",
  Navigator: "icon-q-navigator",
};

const sort = ['*', 'Other']
const getSortPosition = (category) => {
  const index = sort.indexOf(category);
  if (index === -1) { return sort.indexOf('*') }
  return index;
}

const junkRegex = /\s|\&|\-\_/g;
const getIcon = (category) => {
  const key = Object.keys(iconsMap).find(
    (key) =>
      key.toLowerCase().replace(junkRegex, "") ===
      category.toLowerCase().replace(junkRegex, "")
  );

  return iconsMap[key];
};

export default {
  components: {
    ResourcesGrid,
    SearchForm,
    TopSupportQuestions,
    SubmitTicketCard,
    RequestFeatureCard,
  },

  computed: {
    categories() {
      const categories = Category.query().where('zendesk_category_id', (value) => (value != 0 && value != null)).get();

      return categories
        .map(({ category }) => ({
          text: category,
          // TODO: Use icon from vuex orm
          icon: getIcon(category),
          link: { name: "SupportSolution", params: { category } },
        }))
        // TODO: We filter all items without icon, but we need to take it from backend
        .filter(({ icon }) => Boolean(icon))
        .sort((a, b) => a.text.localeCompare(b.text))
        .sort((a, b) => getSortPosition(a.text) - getSortPosition(b.text));
    }
  },
};
</script>
<style lang="scss" scoped>
h1,
h2 {
  letter-spacing: 0px;
  color: #000000;
  font: normal normal bold 45px/45px Montserrat;
}

h2 {
  font-size: 35px;
  font-weight: 300 !important;
}
</style>
