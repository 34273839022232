<template>
	<div class="funnel-stats-cards-wrapper" :class="computedClass">
		<div
			v-for="({ label, value }) in computedItems" :key="label" class="status-summary-card">
			<div class="status-summary-card__label">{{ label }}</div>
			<div class="status-summary-card__value">{{ value }}</div>
		</div>
	</div>
</template>

<script>
import { statusOptions } from '../config/funnelConfigs.js'
import { FunnelStats } from '../api';

export default {
	props: {
		items: {
			type: FunnelStats,
			default: () => [],
		},
		loading: {
			type: Boolean,
			default: false,
		},
		error: {
			type: Boolean,
			default: false,
		}
	},
	computed: {
		isMobileView () {
			return this.$vuetify.breakpoint.xs
		},
		isTabletView () {
			return this.$vuetify.breakpoint.smAndDown && !this.isMobileView
		},
		computedClass () {
			return [
				this.isMobileView && 'funnel-stats-cards-wrapper--mobile',
				this.isTabletView && 'funnel-stats-cards-wrapper--tablet',
				this.loading && 'funnel-stats-cards-wrapper--loading',
				this.error && 'funnel-stats-cards-wrapper--error',
			]
		},
		computedItems () {
			return statusOptions.map(({ key, label, labelShort }) => ({
				type: key,
				label: label,
				value: this.items[key] || 0,
			}))
		},
	},
}
</script>

<style lang="scss" scoped>
@import '../style/style.scss';

.funnel-stats-cards-wrapper {
	--gap: 2rem;
	--min-width-card: 14rem;
	--min-height-card: 4.25rem;
	--height-card: 4.25rem;
	--font-size-label: 1.125rem;
	--font-size-value: 1.5rem;
	--padding-card: 0.5rem 0.75rem;
	--white-space: nowrap;
	--justify-content: center;

	&--tablet {
		--min-width-card: 14rem;
	}

	&--mobile {
		--gap: 1rem;
		--min-width-card: calc(50% - 8px);
		--min-height-card: 4rem;
		--height-card: unset;
		--font-size-label: 1rem;
		--padding-card: 0.5rem 0.5rem;
		--white-space: unset;
		--justify-content: space-between;
	}

	width: 100%;
	display: flex;
	flex-wrap: wrap;
	gap: var(--gap);

	&.funnel-stats-cards-wrapper--mobile {
		justify-content: center;
	}

	&.funnel-stats-cards-wrapper--loading,
	&.funnel-stats-cards-wrapper--error {
		.status-summary-card__value {
			opacity: 0;
		}
	}

	.status-summary-card {
		width: var(--min-width-card);
		min-height: var(--min-height-card);
		height: var(--height-card);
		padding: var(--padding-card);
		display: flex;
		flex-direction: column;
		justify-content: var(--justify-content);
		border-radius: $border-radius-small;
		border: $border-dark;
		border-left: 1rem solid $color-primary;
		background-color: $color-white;
		color: $color-black;
		overflow-x: hidden;

		&__label {
			font-size: var(--font-size-label);
			line-height: 1.3;
			white-space: var(--white-space);
		}

		&__value {
			margin-top: 0.1rem;
			font-size: var(--font-size-value);
			font-weight: bold;
			line-height: 1;
			transition: opacity 0.2s ease-in-out;
		}
	}
}
</style>
