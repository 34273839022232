import api from '../../../store/API/QuilityAPI'

export type Filters = {
  StatType: "BaseShop" | "TotalAgency",
  search: string,
}

export type FunnelStats = {
  "WaitlistCount": number,
  "CertifiedCount": number,
  "FunnelAccessCount": number,
  "SubscriptionCount": number,
  "MonthlySubscriptionTotal": number,
}

export const getFunnelStats = ( agentCode : string ) => {
    return api.getRequest(`/api/private/funnel_dash/${agentCode}`).then((response) => {
        return response.data as FunnelStats[]
    }) 
}