<template>
  <v-dialog v-model="doShowResults" width="80%" max-width="800px">
    <template #activator="{ attrs }">
      <div class="support-seach-form pa-2" v-bind="attrs">
        <v-text-field
          v-model="search"
		  :disabled="isDemoMode"
          class="support-seach-form__input pa-0"
          outlined
          dense
          hide-details
          placeholder="Type your question here or click a tile below"
          v-on:keyup="performSearch"
        />
        <v-btn class="mr-0" color="primary" @click="onSearch">
          Search
          <v-icon v-if="!loading" right>fa fa-search</v-icon>
          <v-progress-circular
            v-else
            indeterminate
            size="20"
          ></v-progress-circular>
        </v-btn>
      </div>
    </template>
    <v-card>
      <v-card-title>
        <h5 class="pt-5">Quick Results</h5>
      </v-card-title>
      <v-card-text>
        <v-expansion-panels
          v-if="pages && pages.length > 0"
          dense
          accordion
          class="elevation-0"
        >
          <v-expansion-panel v-for="(result, k) in pages" :key="k">
            <v-expansion-panel-header
              v-if="result.external_url"
              @click="resourceLink(result)"
            >
              <strong
                >{{ result.title }}
                <v-icon small v-if="result.external_url != 'internal'" left
                  >fas fa-external-link-alt</v-icon
                >
                <v-icon small v-else left>fas fa-link</v-icon>
              </strong>
            </v-expansion-panel-header>
            <v-expansion-panel-header v-else>
              <strong>{{ result.title }} </strong>
            </v-expansion-panel-header>
            <v-expansion-panel-content v-if="!result.external_url">
              <div
                class="cms pa-8"
                v-for="block in result.blocks"
                v-html="block.text"
              ></div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <h6 v-else class="text-center">No results found</h6>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import QuilityAPI from "@/store/API/QuilityAPI.js";

export default {
  name: "SupportSearchForm",

  data() {
    return {
      search: "",
      pages: [],
      doShowResults: false,
      loading: false,
    };
  },

  methods: {
    onSearch() {
      this.loading = true;
      QuilityAPI.getSearchSupportResults({
        search: this.search,
        itemsPerPage: 6,
        page: 1,
      }).then((json) => {
        this.loading = false;
        this.pages = json.pages.data;
        this.doShowResults = true;
      });
    },

    loadPage(result) {
      if (result.external_url && result.external_url !== "internal") {
        window.open(result.external_url, "_blank");
      } else {
        this.$router.push("/page/" + result.slug);
      }
      this.search = "";
    },
    performSearch: function (e) {
      if (e.keyCode === 13) {
        this.onSearch();
      }
    },
    resourceLink: function (item) {
      if (item.external_url && item.external_url != "internal") {
        window.open(item.external_url, "_blank");
      } else {
        this.$router.push("/page/" + item.slug);
      }
    },
  },
};
</script>
<style lang="scss">
.support-seach-form {
  display: flex;
  align-items: center;

  & > * {
    margin: 0 18px;
  }

  &__input {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-sizing: border-box;

    .v-text-field--outlined fieldset {
      border: 1px solid #707070 !important;
    }
  }
}
</style>