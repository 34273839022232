<template>
    <div>
        <div v-for="(comment, id) in ticket.comments" :key="comment.id">
            <!-- This is a customer service response //-->
            <v-card v-if="isCustomerService(comment.author_id) && showPrivateComments(comment.public)" class="mb-5 supportr" elevation="0">
                <v-card-title class="pa-0 px-2" style="background : #FF9" v-if="!comment.public">
                    <h6>INTERNAL NOTE</h6>
                </v-card-title>
                <v-card-text>
                    <p>
                        <v-icon class="pa-1" small>icon-q-support</v-icon>
                        <br /><strong>Support</strong><br />Replied on {{ formatDateDash(comment.created_at) }}
                    </p>
                    <div>
                        <strong v-html="comment.html_body"></strong>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-menu open-on-hover bottom offset-y bottom v-for="(attachment, key) in comment.attachments" :key="key">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn :href="attachment.content_url" target="_blank" color="primary" depressed v-bind="attrs" v-on="on">
                                <v-icon>fas fa-paperclip</v-icon>
                            </v-btn>
                        </template>
                        <v-list dense>
                            <v-list-item>
                                <v-list-item-title class="py-4">File Attached:<br /><strong>{{attachment.file_name}}</strong></v-list-item-title>
                            </v-list-item>
                            <v-list-item>
                                <v-btn tile depressed color="primary" :href="attachment.content_url" target="_blank">Download <v-icon class="pl-3">fas fa-download</v-icon>
                                </v-btn>
                            </v-list-item>
                            <v-list-item v-if="hasPermission('manage:Agent') && ticket.agent != null" @click="transferTicketAttachement(attachment.id)">
                                <v-list-item-icon>
                                    <v-icon>fas fa-file-import</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>Move to Agent's Documents</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-card-actions>
            </v-card>
            <!-- This is the agents comments //-->
            <v-card v-else-if="showPrivateComments(comment.public)" class="mb-5 supportq" elevation="0">
                <v-card-title class="pa-0 px-2" style="background : #FF9" v-if="!comment.public">
                    <h6>INTERNAL NOTE</h6>
                </v-card-title>
                <v-card-text>
                    <p>
                        <v-icon class="pa-1" small color="q_blue_1" style="font-size:30px !important;">fas fa-smile</v-icon><br />
                        <strong>{{you_label}}</strong><br />Replied on {{ formatDateDash(comment.created_at) }}
                    </p>
                    <div v-html="comment.html_body"></div>
                </v-card-text>
                <v-card-actions>
                    <v-menu open-on-hover bottom offset-y bottom v-for="(attachment, key) in comment.attachments" :key="key">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn :href="attachment.content_url" target="_blank" color="primary" depressed v-bind="attrs" v-on="on">
                                <v-icon>fas fa-paperclip</v-icon>
                            </v-btn>
                        </template>
                        <v-list dense>
                            <v-list-item>
                                <v-list-item-title class="py-4">File Attached:<br /><strong>{{attachment.file_name}}</strong></v-list-item-title>
                            </v-list-item>
                            <v-list-item>
                                <v-btn tile depressed color="primary" :href="attachment.content_url" target="_blank">Download <v-icon class="pl-3">fas fa-download</v-icon>
                                </v-btn>
                            </v-list-item>
                            <v-list-item v-if="hasPermission('manage:Agent') && ticket.agent != null" @click="transferTicketAttachement(attachment.id)">
                                <v-list-item-icon>
                                    <v-icon>fas fa-file-import</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>Move to Agent's Documents</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-card-actions>
            </v-card>
        </div>
    </div>
</template>
<script>
import QuilityAPI from '@/store/API/QuilityAPI.js'

export default {
    name: 'QTicketCommentList',
    props: ['ticket'],
    data: function() {
        return {

        }
    },
    mounted: function() {

    },
    computed: {
        you_label: function() {
            if (this.hasRole(['Exec', 'SuperAdmin', 'Staff'])) {
                return 'Agent';
            }
            return "You";
        }
    },
    methods: {
        isCustomerService: function(id) {
            return this.ticket.requester_id != id;
        },
        transferTicketAttachement(attachment_id) {
            var g = this
            QuilityAPI.transferTicketAttachement(attachment_id, this.ticket.agent.AgentCode).then((json) => {
                if (json.error) {
                    this.showError(json.message || json.msg)
                    return;
                }
                this.showSuccess("Successfully Transferred!")
            })
        },
        showPrivateComments: function(is_public) {
            if (is_public === true) {
                return true;
            }
            if (this.hasRole(['Exec', 'SuperAdmin', 'Staff'])) {
                return true;
            }
        }
    }
}

</script>
